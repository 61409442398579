<template>
  <div id="wrapper" class="bg-w noto-sans fc-b">

    <div class="pt-3 mt-3 container">

      <div class="pt-3 df-center" style="justify-content: space-between;">
        <div class="top-title df-center">
          <h4 class="mr-1">하우머치</h4>
          <span>(온라인 견적요청)</span>
        </div>

        <div class="top-title df-center">
          <span class="fc-g">자재를 온라인을 통해 손쉽게 견적 받아보세요</span>
        </div>
      </div>
      <hr style="margin-top:0.125rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

      <div class="df-center mt-4 px-4 py-3" style="border: solid 1px #dcdcdc; border-radius: 2px;">
        <div class="df-start df-center">
          <p class="fb fs-14 mr-3">전체 견적현황</p>
          <ul class="df mb-0">
            <li class="mr-5 df-center">
              <p class="fs-7 mr-2">■</p>
              전체 <p class="ml-1 fs-15 fc-r" style="font-weight: 500;">{{ calcCount.ALL }}</p>건
            </li>
            <li class="mr-5 df-center">
              <p class="fs-7 mr-2">■</p>
              현금 <p class="ml-1 fs-14" style="font-weight: 500;">{{ calcCount.CASH }}</p>건
            </li>
            <li class="mr-5 df-center">
              <p class="fs-7 mr-2">■</p>
              카드 <p class="ml-1 fs-14" style="font-weight: 500;">{{ calcCount.CARD }}</p>건
            </li>
          </ul>
        </div>
      </div>

      <div class="px-1" style="margin: 1.525rem 0 1.252rem 0;">
        <div class="mt-4 mb-2" style="width: 30%;">
          <b-input-group>
            <template #prepend>
              <b-dropdown variant="outline-secondary" toggle-class="br-0 drop-down-custom" :text="searchOpt.label">
                <b-dropdown-item v-for="(item, index) in searchOpts" :key="index" @click="searchOptSet(index)">{{ item.label }}</b-dropdown-item>
              </b-dropdown>
            </template>

            <b-form-input v-model="keyWord" placeholder="검색어를 입력하세요." @keydown.enter="getList(true)" />

            <template #append>
              <b-input-group-append>
                <b-button style="background-color: #9a9a9a; color: white;" variant="outline-secondary" @click="getList(true)">
                  <b-icon icon="search" />
                </b-button>
              </b-input-group-append>
            </template>
          </b-input-group>
        </div>

        <div>
          <b-table
              :items="items"
              :fields="fields"
              sort-icon-left
              hover
              responsive="sm"
              class="items-table"
              thead-tr-class="thead-bg"
          >
            <template #cell(regNick)="data">
              <div>
                <p v-if="data.item.regId === user.id || user.role === 'A' "> {{ data.item.regNick }} </p>
                <p v-else> {{ nickMasking(data.item.regNick) }} </p>
              </div>
            </template>

            <template #cell(title)="data">
              <div class="df-center">
                <div class="df-center fc-b c-href-pointer" @click="userViewRoleVerify(data.item)">
                  <p class="mr-1 fb">[{{ payTypeConv(data.item.payType) }}]</p>
                  <p>{{ data.item.title }}</p>
                </div>
                <tooltip text="비공개 견적입니다.">
                  <b-icon v-if="data.item.isSecret" icon="lock-fill" class="fb fc-g" />
                </tooltip>
              </div>
            </template>

            <template #cell(status)="data">
              <p :class="statusClass(data.item.status)">{{ statusTypeConv(data.item.status) }}</p>
            </template>
          </b-table>
        </div>
      </div>

      <div class="mt-4 mb-5 df-align-center" v-if="calcCount.ALL > perPage">
        <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="row"
            hide-ellipsis
            hide-goto-end-buttons
        >
        </b-pagination>
      </div>

    </div>

<!--    <Spinner :showSpinner="loading" />-->

  </div>
</template>


<script>
import {
  apiCall,
  apiMultiPart,
  cloneVar,
  alertError,
  alertWarn,
  alertSuccess,
  alertConfirm,
  alertPageMove,
  warningAlertPageMove,
  getMask,
  warpSite, commify, humanSize, nickMask, warpSiteDirectMove,
} from "@/common/utils";
import moment from 'moment';
import {status, payType, statusClass} from '@/common/quoteType';
import store from "@/store";

export default {
  name: 'HowMuchViewList',
  components: {},
  data() {
    return {
      loading: false,
      fields: [
        { key: "createdAt", label: "신청일", thClass: "w10", tdClass: "td-center", formatter: v =>{ return this.dateConv(v) } },
        { key: "regNick", label: "닉네임", thClass: "w15", tdClass: "td-center" },
        { key: "title", label: "제목", thClass: "w50" },
        // { key: "", label: "입찰수", thClass: "w10", tdClass: "td-center" },
        { key: "status", label: "진행상태", thClass: "w20", tdClass: "td-center", formatter: v =>{ return this.statusTypeConv(v) } },
      ],
      items: [],
      calcCount: {},

      row: 0,
      perPage: 20,
      currentPage: 1,
      keyWord: '',
      searchOpt: { key: "title", label: "제목" },
      searchOpts: [
        { key: "title", label: "제목" },
      ]
    };
  },

  watch: {
    currentPage() {
      this.getList(true);
    },
  },

  computed: {
    user() { return this.$store.state.userStore.user; },
    isAuth() { return this.$store.state.userStore.isAuth; }
  },

  async created() {
    await this.getList(false);
  },

  methods: {
    dateConv(date) { return moment(date).format('MM.DD HH:mm') },
    nickMasking(nick) { return nickMask(nick) },
    statusTypeConv(type) { return status[type] },
    payTypeConv(type) { return payType[type] },
    statusClass(status) { return statusClass[status] },
    searchOptSet(index) { this.searchOpt = this.searchOpts[index] },
    userViewRoleVerify(item) {
      if ( item.isSecret && this.user.role !== 'A' && this.user.id !== item.regId ) {
        alert("비공개 견적은 열람할 수 없습니다.");
        return;
      }
      if ( item.status === 'CANCEL' && this.user.role !== 'A' && this.user.id !== item.regId ) {
        alert("취소된 견적은 열람할 수 없습니다.");
        return;
      }
      if ( !this.isAuth ) {
        if (confirm("로그인이 되어있지 않아 열람할 수 없습니다. 로그인 하시겠습니까?")) {
          return warpSiteDirectMove('service/login');
        }
        return;
      }

      return warpSiteDirectMove(`service/howmuch/detail/${item.seq}`);
    },

    async getList(isSearch) {
      let query = {
        perPage: this.perPage,
        currentPage: this.currentPage,
      };

      try {
        if (isSearch && this.keyWord !== '') {
          query.isSearch = isSearch;
          query.searchOpt = this.searchOpt.key;
          query.keyWord = this.keyWord;

          if (!query.searchOpt) return alert("검색 옵션이 없습니다. 선택해주세요.");
          if (query.keyWord.length < 2) return alert("검색어는 2글자 이상 입력해주세요.");
        }

        const r = await apiCall('GET', `/api/how-much/view-list`, query);
        if (r.code === 200) {
          this.items = r.result.list;
          this.calcCount = r.result.calcCount;
          this.row = r.result.pageAllCount;
        }

      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style>

tr {
  border-bottom: solid 1px #dbdbdb;
}

.thead-bg {
  background-color: #ededed;
  text-align: center;
}

.td-center {
  text-align: center;
}

/* Paging Class */
.page-link {
  color: grey;
  border-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
}
.page-item.active .page-link {
  background-color: rgba(0, 0, 0, 0);
  color: black;
  border-color: #bababa;
}
.page-item.disabled .page-link {
  border-color: rgba(0, 0, 0, 0);
}
/* Paging Class */

.drop-down-custom {
  background-color: white;
  color: #9a9a9a;
  border-color: grey;
}

</style>